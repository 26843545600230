export default {
    toolbarButtons: [
       'camera',
       'chat',
    //    'closedcaptions',
       'desktop',
    //    'download',
    //    'embedmeeting',
    //    'etherpad',
    //    'feedback',
       'filmstrip',
       'fullscreen',
       'hangup',
    //    'help',
    //    'invite',
    //    'livestreaming',
       'microphone',
       'mute-everyone',
       'mute-video-everyone',
    //    'participants-pane',
       'profile',
       'raisehand',
       'recording',
       'security',
       'select-background',
       'settings',
    //    'shareaudio',
    //    'sharedvideo',
    //    'shortcuts',
       'stats',
       'tileview',
    //    'toggle-camera',
       'videoquality',
       '__end'
    ],
    excludeNonModerator: [
        'mute-everyone',
        'security',
        'settings'
    ]

}

