<template>
  <div class="meeting">
    <div
      v-if="!jitsiState"
      class="image-wrapper"
      @click.prevent="rejoin">
      <img
        v-if="previewScreen"
        :src="previewScreen"
        class="previewScreen"
      />
    </div>
    <!-- Controls -->
    <div
      class="meetingControls"
      v-if="meetingStatus === 'started'">
      <c-button
        icon="/icons/user-add.svg"
        @click.prevent="showInviteDialog = true">
      </c-button>
      <c-button
        icon="/icons/exit.svg"
        @click.prevent="showEndMeetingDialog = true">
      </c-button>
    </div>
    <!-- Dialog -->
    <confirm-modal
      @success="endMeeting"
      title="Meeting beenden"
      v-if="showEndMeetingDialog"
      @close="showEndMeetingDialog = false"
      :successButtonTitle="$t('meeting.end_meeting')">
      {{ $t('meeting.end_meeting_message') }}
    </confirm-modal>
    <invite-modal
      :category="category"
      v-if="showInviteDialog"
      @close="showInviteDialog = false">
    </invite-modal>
  </div>
</template>

<script>
  import JitsiMeetExternalAPI from '../../external/jitsiExternalApi'
  import JitsiConfiguration from '../../external/dubidot-jitsi'
  import {mapGetters} from 'vuex'

  export default {
    props: [
      'layout',
      'category',
      'isDigitalSignage',
    ],
    computed: {
      ...mapGetters({
        user: 'auth/user',
      }),
      meetingRoom() {
        return this.category?.meetingRoom + this.category?.id
      },
      previewScreen() {
        if (!this.meetingStatus || this.meetingStatus === 'ended') {
          return this.category
            && this.category.otherDocuments
            && this.category.otherDocuments[0]
            && this.category.otherDocuments[0].href
            ? this.category.otherDocuments[0].href
            : null
        }
        let media = this.category?.media?.links?.find(l => l.rel === 'download')?.href
        if (!media) {
          media = this.category?.media?.links?.find(l => l.rel === 'preview')?.href
        }
        return media
      }
    },
    data() {
      return {
        jitsiApi: null,
        jitsiState: null,
        meetingStatus: null,
        showInviteDialog: false,
        showEndMeetingDialog: false,
      }
    },
    created() {
      if (!this.category.meetingStatus) this.meetingStatus = 'ended'
      else
        this.meetingStatus = this.category.meetingStatus
    },
    methods: {
      async initializeJitsiAPI() {
        // Check the meeting status. When the status is started a normal user
        // can join the meeting. Only an admin or user with summon.meeting rights
        // can start the meeting.
        let rights = []
        let mainRole = '2'
        if ((this.user && this.user.workflow) || this.user.mainRole === '1') {
          rights = this.user.workflow || this.user.rights || []
          mainRole = this.user.mainRole
          const canSummonMeeting = this.user.mainRole === '1' || rights.includes('Summon.Meeting')
          if (canSummonMeeting && this.meetingStatus !== 'started') {
            await this.$api.setMeetingStatus(this.category.id, 'started')
            this.meetingStatus = 'started'
          }
        }

        if (this.meetingStatus
          && this.meetingStatus === 'started') {
          // Set the size of the meeting iframe element
          let rootElement = document.querySelector('.meeting')
          let rootHeight = document.querySelector('.navigation-content').clientHeight
          if (this.layout === 'splitview') {
            rootHeight = document.querySelector('.splitpanes .splitpanes--vertical').clientHeight
          }

          // Configure toolbar buttons for different roles (ADMIN/MODERATOR/USER)
          let toolbarConfiguration = JitsiConfiguration.toolbarButtons
          if (mainRole !== '1' && (!rights.includes('Summon.Meeting'))) {
            toolbarConfiguration = toolbarConfiguration.filter(toolbarItem => !JitsiConfiguration.excludeNonModerator.includes(toolbarItem))
          }

          this.jitsiState = 'started'
          this.jitsiApi = new JitsiMeetExternalAPI(this.category.meetingServer, {
            noSSL: false,
            height: rootHeight,
            parentNode: rootElement,
            roomName: this.meetingRoom,
            jwt: this.category.meetingJwt,
            userInfo: {
              displayName: this.$store.getters['auth/username'],
              email: this.$store.getters['auth/email'],
            },
            configOverwrite: {
              disableRemoteMute: true,
            },
            interfaceConfigOverwrite: {
              TOOLBAR_BUTTONS: toolbarConfiguration
            }
          })
          // this.jitsiApi.addEventListeners({
          //   videoConferenceJoined: this.videoConferenceJoined
          // })
          this.jitsiApi.on('passwordRequired', () => {
            this.jitsiApi.executeCommand('password', this.category.meetingPwd)
          })
          this.jitsiApi.on('readyToClose', () => {
            this.jitsiState = null
            rootElement.querySelector('iframe').remove()
          })
        }
      },
      rejoin() {
        this.initializeJitsiAPI()
      },
      async endMeeting() {
        await this.$api.setMeetingStatus(this.category.id, 'ended')
        this.meetingStatus = null
        this.showEndMeetingDialog = false
        if (this.jitsiApi) {
          this.jitsiApi.executeCommand('hangup')
        }
      },
      async getGuest() {
        let authResponse = await this.$api.login('guest', 'guest')
        authResponse = await authResponse.json()
        if (authResponse && !authResponse.error) {
          this.$store.commit('auth/setUser', authResponse)
        }
        return authResponse
      }
    },
    inject: [
      '$helper',
      '$api',
    ],
  }
</script>

<style lang="scss" scoped>
  .meeting {
    @apply
      h-full
      w-full
      relative
      max-w-full
      max-h-full
      overflow-hidden;

    .image-wrapper {
      @apply
        flex
        w-full
        h-full
        bg-white
        items-center
        justify-center;
    }

    .previewScreen {
      @apply
        w-full
        h-full
        object-cover
        max-w-full
        max-h-full
        cursor-pointer;
    }
  }

  .meetingControls {
    @apply
      z-50
      flex
      gap-2
      absolute
      right-2
      bottom-2;
  }
</style>